import React from "react";

import Layout from "../components/layout";
import Container from "../components/container";

const Terms = () => (
  <Layout title="Terms of Service - Supersheets">
    <Container>
      <div className="max-w-2xl m-auto mb-16">
        <h1 className="font-serif text-center font-light text-3xl md:font-medium p-4 md:p-8">
          Supersheets: End User licence agreement
        </h1>
        <Para>
          This is your End User Licence Agreement for Supersheets. Please read
          this carefully before using the Supersheets service and you may want
          to retain a copy for your records. When you accept these terms, you
          confirm that you are duly authorised on behalf of the Client to place
          an order for Supersheets subject to the terms and conditions set out
          below. You also warrant that the information submitted in signing up
          is correct and accurate to the best of your knowledge.
        </Para>
        <Para>
          <Bold>A: PROPERTY OF SUPERSHEETS</Bold> FOLLOWING ACCEPTANCE OF THIS
          END USER LICENCE AGREEMENT YOU MAY ACCESS AND USE THE SUPERSHEETS
          SOFTWARE THROUGH OUR SERVER. THE COPYRIGHT, DATABASE RIGHTS AND ANY
          OTHER INTELLECTUAL PROPERTY RIGHTS IN THE PROGRAMS AND DATA WHICH
          CONSTITUTE THIS SOFTWARE PRODUCT, ARE AND REMAIN THE PROPERTY OF
          SUPERSHEETS LTD ('SUPERSHEETS', 'WE', 'US' AND 'OUR').
        </Para>
        <Para>
          <Bold>B: LICENCE ACCEPTANCE PROCEDURE</Bold> BY COMPLETING THE
          REGISTRATION PROCEDURE, YOU INDICATE ACCEPTANCE OF THIS END USER
          LICENCE AGREEMENT AND THE LIMITED WARRANTY AND LIMITATION OF LIABILITY
          SET OUT IN THIS END USER LICENCE AGREEMENT. SUCH ACCEPTANCE IS ON
          BEHALF OF ANY CORPORATE ENTITY WHICH EMPLOYS YOU OR WHICH YOU
          REPRESENT ('CLIENT'). IN THIS END USER LICENCE AGREEMENT, 'YOU'
          INCLUDES BOTH THE READER AND ANY CLIENT. YOU SHOULD THEREFORE READ
          THIS END USER LICENCE AGREEMENT CAREFULLY BEFORE COMPLETING THE
          REGISTRATION PROCEDURE. IF YOU DO NOT AGREE TO THE TERMS OF THIS END
          USER LICENCE AGREEMENT, DO NOT USE THE WEBSITE OR THE SERVICE.
        </Para>
        <Para>
          <Bold>C: USER REJECTION</Bold> SUPERSHEETS MAY IN OUR ABSOLUTE
          DISCRETION REJECT YOUR COMPANY FROM USING THE SUPERSHEETS SERVICE
          WITHIN 21 DAYS OF SUBMISSION, IN WHICH CASE YOU WILL BE NOTIFIED OF
          SUCH REJECTION BY E-MAIL AND SHALL BE REFUNDED ANY SUBSCRIPTION FEES
          PAID TO SUPERSHEETS IN RESPECT OF SUCH PERIOD IN CONNECTION WITH A
          PREMIUM SERVICE (IF ANY).
        </Para>
        <Heading>Definitions</Heading>
        <Para>
          The following terms as used in this Agreement have the following
          meanings:
        </Para>
        <Para>
          <Bold>"Agreement"</Bold> means this End User Licence Agreement which
          may be amended by Supersheets from time to time in accordance with its
          terms;
        </Para>
        <Para>
          <Bold>"Supersheets"</Bold> means the online data accessibility
          platform managed by Supersheets and provided through the Website and
          other properties;
        </Para>
        <Para>
          <Bold>"Supersheets Technology"</Bold> means all the proprietary
          technology used in delivering the Service (including the software,
          hardware, products, processes, algorithms, user interfaces, know-how,
          techniques, designs and other tangible or intangible technical
          material or information) made available to the Client by Supersheets
          in providing the Service;
        </Para>
        <Para>
          <Bold>"Client", "you", "yours"</Bold> means the corporate entity or
          organisation ordering the Service(s);
        </Para>
        <Para>
          <Bold>"Client Data"</Bold> means any data, information or material
          provided or submitted by or on behalf of the Client to Supersheets
          and/or the Service or generated by the Service in the course of using
          the Service including but not limited to employee data held in the
          Service;
        </Para>
        <Para>
          <Bold>"Content"</Bold> means the documents, software, materials,
          products and services contained or made available to the Client in the
          course of using the Service;
        </Para>
        <Para>
          <Bold>"Defect"</Bold> means an error in the operation of the Service
          that causes the Service to fail to operate substantially as
          documented;
        </Para>
        <Para>
          <Bold>"Effective Date"</Bold> means the date upon which this Agreement
          is accepted by the Client;
        </Para>
        <Para>
          <Bold>"Intellectual Property Rights"</Bold> means unpatented
          inventions, patent applications, patents, design rights, copyrights
          (including rights in computer software), trade marks, service marks,
          trade names, domain name rights, database rights, design rights, mask
          work rights, know-how and other trade secret rights, and all other
          intellectual property rights, derivatives thereof, and forms of
          protection of a similar nature anywhere in the world;
        </Para>
        <Para>
          <Bold>"Law"</Bold> means any applicable law (statutory, common or
          otherwise), legislation (primary or secondary), statutory provision,
          statutory instrument, constitution, treaty, convention, ordinance,
          equitable principle, code, directive, edict, decree, rule, order,
          requirement, regulation, guidance, executive order, or other similar
          authority issued, enacted, adopted, promulgated, implemented, applied,
          or otherwise put into legal effect by or under the authority of any
          governmental entity in any relevant jurisdiction;
        </Para>
        <Para>
          <Bold>"Service(s)"</Bold> means the online services developed,
          operated, and maintained by Supersheets (and its licensors, where
          applicable), or ancillary online or offline products and services
          provided to the Client by Supersheets, to which the Client is being
          granted access under this Agreement, including the Supersheets
          Technology and the Content;
        </Para>
        <Para>
          <Bold>"User(s)"</Bold> means the Client's employees, representatives,
          consultants, contractors or agents who are authorised to use the
          Service and have been supplied user identifications and passwords by
          the Client (or by Supersheets at the Client's request); and
        </Para>
        <Para>
          <Bold>"Website"</Bold> means{" "}
          <a href="https://supersheets.so" className="text-indigo-500">
            https://supersheets.so
          </a>
        </Para>
        <Heading>
          <Bold>Privacy; Disclosure</Bold>
        </Heading>
        <Para>
          Supersheets's privacy policy in relation to the Client's use of the
          Service may be viewed at supersheets.so, and is incorporated into this
          Agreement by reference. Supersheets reserves the right to modify its
          privacy policy in its reasonable discretion from time to time. With
          regard to any personal data input by or collected from the Client that
          may be stored or processed in the Supersheets system, such data shall
          be stored and processed by Supersheets in accordance with UK data
          protection legislation. Note that because the Service is a hosted,
          online application, Supersheets occasionally may need to notify all
          Users of the Service of important announcements regarding the
          operation of the Service. The Client agrees that Supersheets can
          disclose the fact that the Client is a user of the Service.
        </Para>
        <Para>
          If you do not agree with Supersheets's privacy policy then please do
          not use the Website or the Service.
        </Para>
        <Heading>
          <Bold>Registration</Bold>
        </Heading>
        <Para>
          Upon signing up for the Service and at subsequent times as requested
          by Supersheets, You agree to provide to Supersheets your true,
          accurate, current, and complete personal name and/or business name,
          admin name, billing address, the addresses where the Service will
          primarily be used, email address, contact phone number, credit/debit
          card information, and other data which may be necessary to administer
          your account (collectively, "<Bold>Registration Data</Bold>"). You
          represent and warrant that the information you provide is accurate,
          current, and complete, and agree to promptly update any of the
          information if it changes.
        </Para>
        <Para>
          If you provide Registration Data that is, or that Supersheets suspects
          to be, false, inaccurate, not current, incomplete, fraudulent, or
          otherwise unlawful, Supersheets has the right, in its sole discretion,
          to suspend or terminate the Service and refuse any and all current or
          future use of the Service by you, your business(es), affiliates and
          all users of your account. At all times, you shall maintain and
          promptly update Registration Data.
        </Para>
        <Heading>
          <Bold>Licence Grant and Restrictions</Bold>
        </Heading>
        <Para>
          Subject to and conditional upon the Client's compliance with the terms
          of this Agreement, Supersheets hereby grants to the Client a
          non-exclusive, limited, personal, revocable, non-transferable, right
          to use the Service, solely for the Client's own internal business
          purposes, subject to the terms and conditions of this Agreement.
        </Para>
        <Para>
          All rights not expressly granted to the Client are reserved by
          Supersheets and its licensors.
        </Para>
        <Para>
          The Client may not access the Service if they are a direct competitor
          of Supersheets or any of Supersheets's businesses or associated
          companies, except with Supersheets's prior written consent.
        </Para>
        <Para>
          In addition, the Client may not access the Service for purposes of
          monitoring its availability, performance or functionality, or for any
          other benchmarking or competitive purposes. The Client shall not: (i)
          license, sublicense, sell, resell, transfer, assign, rent, lease,
          export, import, act as an intermediary or provider, distribute or
          otherwise commercially exploit or make available to any third party
          the Service or the Content in any way; (ii) modify or make derivative
          works based upon the Service or the Content; (iii) create Internet
          "links" to the Service or "frame" or "mirror" any Content on any other
          server or wireless or Internet-based device; or (iv) reverse engineer
          or access the Service in order to (a) build a competitive product or
          service, (b) build a product using similar ideas, features, functions
          or graphics of the Service, or (c) copy any ideas, features, functions
          or graphics of the Service.
        </Para>
        <Para>
          The Client may use the Service only for internal business purposes and
          shall not (not authorise any third party to): (i) store infringing,
          obscene, threatening, libellous, or otherwise unlawful or tortious
          material, including material harmful to children or in violation of
          third party privacy rights; (ii) store material containing software
          viruses, worms, Trojan horses or other harmful computer code, files,
          scripts, agents or programs; (iii) interfere with or disrupt the
          integrity or performance of the Service or the data contained therein;
          or (iv) attempt to gain unauthorised access to the Service or its
          related systems or networks.
        </Para>
        <Para>
          Supersheets, in our sole discretion, reserves the right to add,
          remove, or modify features or functions, or to provide fixes, updates
          and upgrades, to Supersheets and the Service. You acknowledge and
          agree that Supersheets has no obligation to make available to You any
          subsequent versions of Supersheets or the Service. In addition, you
          and Supersheets acknowledge that no third-party has any obligation
          whatsoever to furnish maintenance or support services with respect to
          Supersheets or the Service and that Supersheets is solely responsible
          for the provision of maintenance and support as provided in this
          Agreement and to the extent such maintenance and support is required
          under applicable Law.
        </Para>
        <Para>
          Supersheets and the Service may be integrated with third party
          applications, websites, and services ("
          <Bold>Third Party Services</Bold>") to make available content,
          products, and/or services to you. These Third Party Services may have
          their own terms and conditions of use and privacy policies and your
          use of these Third Party Services will be governed by and subject to
          such terms and conditions and privacy policies. You understand and
          agree that Supersheets does not endorse and is not responsible or
          liable for the behaviour, features, or content of any Third Party
          Services or for any transaction you may enter into with the provider
          of any such Third Party Services.
        </Para>
        <Heading>
          <Bold>The Client's Responsibilities</Bold>
        </Heading>
        <Para>
          The Client is solely responsible for all activity occurring under
          their User accounts and shall abide by all applicable Law in
          connection with their and their Users' use of the Service, including
          those related to data privacy, international communications and the
          transmission of technical or personal data. The Client shall: (i)
          notify Supersheets immediately of any unauthorised use of any password
          or account or any other known or suspected breach of security; (ii)
          report to Supersheets immediately and use reasonable efforts to stop
          immediately any copying or distribution of Content that is known or
          suspected by the Client or their Users; and (iii) not impersonate
          another Supersheets User or provide false identity information to gain
          access to or use the Service.
        </Para>
        <Para>
          You shall not use Supersheets or the Service for any illegal,
          fraudulent, improper, or abusive purpose or in any way that interferes
          with Supersheets's ability to provide a high quality Service to other
          clients, prevents or restricts other clients from using the Service,
          or damages any of Supersheets's or other clients' property. If
          Supersheets finds that you are using the Service for anything other
          than as permitted by this Agreement or for any of the prohibited uses
          in this Agreement, Supersheets may at our sole discretion terminate
          your Service with immediate effect. Prohibited uses include, but are
          not limited to:
        </Para>
        <List>
          <li>
            Behaviour that is illegal, obscene, threatening, harassing,
            defamatory, libellous, deceptive, fraudulent, malicious, infringing,
            tortious, or invasive of another's privacy.
          </li>
          <li>
            Harvesting or otherwise collecting information about others,
            including email addresses, without their consent or in breach of
            applicable data protection or privacy laws.
          </li>
          <li>
            Negligently, recklessly, knowingly, or intentionally transmitting
            any material that contains viruses, time bombs, Trojan horses,
            worms, malware, spyware, or any other programs that may be harmful
            or dangerous.
          </li>
          <li>
            Transmitting any material that may infringe, misappropriate, or
            otherwise violate any third party's Intellectual Property Rights.
          </li>
          <li>
            Using the Service in any way that interferes with other clients' and
            third parties' use and enjoyment of the Service or use the Service
            in any manner which disrupts, prevents or restricts any other client
            from using the Service.
          </li>
          <li>
            Using or employing methods and/or devices that are designed or
            likely to take advantage of, bypass, exploit, or otherwise avoid the
            restrictions and limitations contained in this Agreement.
          </li>
        </List>
        <Para>You further understand and agree that:</Para>
        <List>
          <li>
            You shall be solely liable for any transmissions sent through
            Supersheets and the Service under your account, including the
            content of any transmission sent through the Service under your
            account.
          </li>
          <li>
            You will abide by all applicable Supersheets policies, procedures,
            and agreements related to Supersheets and the Service.
          </li>
        </List>
        <Para>
          If we, in our sole discretion, consider you to be in breach of this
          Agreement, or likely to be in breach of this Agreement, we may take
          any action we think is necessary to protect Supersheets, the Service
          and its users. We may: (a) withdraw your right to use Supersheets and
          the Service; (b) remove Client Data from Supersheets; (c) take legal
          proceedings against you; (e) disclose any information to law
          enforcement authorities we think is necessary or as required by Law.
          These actions are not limited and we may take any other action we
          reasonably deem appropriate.
        </Para>
        <Para>
          As you will be submitting and providing Client Data which may contain
          the personal data (and potentially sensitive personal data (as such
          terms are defined under the Act)) of Users and third parties, you
          acknowledge and accept that you are solely responsible for obtaining
          all required consents, authorisations and permissions from such Users
          and third parties to enable you to provide such information to us and
          to grant to us the rights set forth in this EULA and the privacy
          policy. It is your responsibility to ensure that all such Users and
          third parties are aware of and accept the terms of this EULA and the
          privacy policy and that you have obtained explicit consent of Users to
          our processing any of their sensitive personal data in accordance with
          this EULA and the privacy policy. You may not provide us with any
          Client Data or other information containing personal data of Users or
          third parties unless and until you have obtained all necessary
          consents, authorisations and permissions to do so.
        </Para>
        <Heading>
          <Bold>Account Information and Data</Bold>
        </Heading>
        <Para>
          Supersheets does not own any of the Client Data. The Client, not
          Supersheets, shall have sole responsibility for the accuracy, quality,
          integrity, legality, reliability, appropriateness, and intellectual
          property ownership or right to use of all Client Data, and Supersheets
          shall not be responsible or liable for the deletion, correction,
          destruction, damage, loss or failure to store any Client Data.
        </Para>
        <Para>
          In the event this Agreement is terminated (other than by reason of the
          Client's breach), Supersheets shall advise the Client how it may
          retrieve the Client Data but it will be the sole responsibility of the
          Client to retrieve a copy of their Client Data within 30 days of
          termination after which time, to the extent permitted by applicable
          Law, Supersheets may delete all such data.
        </Para>
        <Para>
          Supersheets reserves the right to withhold, remove and/or discard
          Client Data without notice for any breach by the Client without
          limitation. Upon termination by Supersheets for any breach, the
          Client's right to access or use Client Data immediately ceases, and
          Supersheets shall have no obligation to maintain or store or forward
          any Client Data.
        </Para>
        <Heading>
          <Bold>Intellectual Property Ownership</Bold>
        </Heading>
        <Para>
          You hereby grant to Supersheets a perpetual, worldwide, royalty-free,
          fully paid-up, non-exclusive, non-transferable (except in connection
          with an assignment of this Agreement) licence to copy, store, share,
          record, transmit, display, view, print, and use Client Data to the
          extent required to provide or improve Supersheets and the Service and
          in accordance with our privacy policy.
        </Para>
        <Para>
          Supersheets alone (and its licensors, where applicable) shall own all
          right, title and interest, including all related Intellectual Property
          Rights, in and to the Supersheets Technology, the Content, and the
          Service and any suggestions, ideas, enhancement requests, feedback,
          recommendations or other information provided by the Client or any
          other party relating to the Service. This Agreement is not a sale and
          does not convey to the Client any rights of ownership in or related to
          the Service, the Supersheets Technology or the Intellectual Property
          Rights owned by Supersheets. The Supersheets name, the Supersheets
          logo, and the product names associated with the Service are trade
          marks of Supersheets or third parties, and no right or licence is
          granted to use them.
        </Para>
        <Para>
          You are only entitled to the limited use of the rights expressly
          granted to you in this Agreement. You will not take any action to
          jeopardise, limit, restrict or interfere with Supersheets's
          Intellectual Property Rights or do any other action or thing which
          otherwise has the effect of jeopardising, limiting, restricting or
          interfering with Supersheets's ownership or use of such Intellectual
          Property Rights. You acknowledge and agree that any unauthorised use
          of the Supersheets Technology and the Content is a breach of this
          Agreement, as well as a breach of applicable intellectual property
          laws. You acknowledge and understand that all title and rights in and
          to any third party content that may be accessed through Supersheets or
          the Service is the property of the respective content owners and may
          be protected by applicable copyright or other intellectual property
          laws and treaties.
        </Para>
        <Heading>
          <Bold>Set-up and Training Services</Bold>
        </Heading>
        <Para>
          Set-up and training services may be available on request from
          Supersheets. Their availability and cost will be subject to agreement
          between the parties.
        </Para>
        <Heading>
          <Bold>Excess Data Storage Fees</Bold>
        </Heading>
        <Para>
          Disk maximum storage space allowance provided to the Client is at the
          sole discretion of Supersheets and Supersheets reserves the exclusive
          right to amend this at any time without notice.
        </Para>
        <Heading>
          <Bold>Premium Services</Bold>
        </Heading>
        <Para>
          As well as the free service provided to clients, Supersheets may also
          offer additional services, features and resources to premium clients
          for a subscription fee, e.g. our usage based pricing offerings (the
          "Premium Services"). Supersheets provides information on its Website
          about the Premium Services and its additional services and features.
          For Clients with a valid subscription (or free trial (where
          applicable)) to a Premium Service, the Premium Service shall, for the
          purposes of these terms, form part of the "Services".
        </Para>
        <Para>
          We may need certain information from you so that we can supply the
          Premium Services to you. If you do not give us this information, or if
          you give us incomplete or incorrect information, we may either end the
          contract for the supply of the Premium Services or make an additional
          charge of a reasonable sum to compensate us for any extra work that is
          required as a result. We will not be responsible for supplying the
          Premium Services late or not supplying any part of the Premium Service
          if this is caused by you not giving us the information we need.
        </Para>
        <Para>
          The Premium Services are subscription-based services and any minimum
          contract commitment is set out in the relevant Premium Service terms.
        </Para>
        <Para>
          The subscription fee (which includes VAT) for each Premium Service is
          outlined on the Website and in the relevant Premium Service terms and
          will be charged on the first day you subscribe to a Premium Service
          (or following a free trial period (if applicable) and then monthly on
          or after the calendar day corresponding to the commencement of your
          membership subscription ("Renewal Date"). This fee will continue to be
          charged on each Renewal Date thereafter unless and until you cancel,
          and regardless of how much you use the purchased Premium Service and
          we will charge the fee to the debit/credit card that you provide to
          our payment processor partners (including, without limitation, Stripe)
          during registration for such Premium Service. We may change the fees
          and charges in effect, or add new fees and charges from time to time,
          but we will give you advance notice of these changes by email. If you
          do not agree to the change or otherwise no longer want to be a Premium
          Service Client, you may cancel your subscription by emailing us at{" "}
          <a href="mailto:support@supersheets.so" class="text-indigo-500">
            support@supersheets.so
          </a>{" "}
          through your account settings on the Website. The cancellation of your
          Premium Service subscription shall take effect upon expiry of the
          notice period identified in the relevant Premium Service terms, and no
          refund (or partial refund) shall be given in respect of such current
          month. The Client acknowledges that, in respect of some Premium
          Services, the subscription price per month may vary depending on the
          number of active accounts used by the Client during the applicable
          month (which we may determine in our sole discretion).
        </Para>
        <Para>
          You must pay all amounts due to us for a Premium Service in full
          without any set-off, counterclaim, deduction or withholding (other
          than any deduction or withholding of tax as required by law).
        </Para>
        <Heading>
          <Bold>Termination</Bold>
        </Heading>
        <Para>This Agreement commences on the Effective Date.</Para>
        <Para>
          You understand and agree that Supersheets may at any time, and without
          additional notice to you, terminate, modify, suspend, discontinue, or
          block access to some or all of the features of Supersheets or the
          Service if:
        </Para>
        <List>
          <li>
            Supersheets determines that you have materially breached this
            Agreement (without limiting the generality of the foregoing any
            unauthorised use of the Supersheets Technology or Service by the
            Client will be deemed a material and irremediable breach of this
            Agreement).
          </li>
          <li>
            Supersheets determines that you did not or will not reasonably
            comply or cooperate with any applicable Law.
          </li>
          <li>
            Supersheets is ordered by any regulator, law enforcement or other
            government agency to suspend or terminate the Service.
          </li>
          <li>
            You bring any legal action or proceeding, including without
            limitation in any court, regulatory, or administrative body,
            arbitral body, or mediator, against Supersheets, or participate in
            any class action lawsuit against Supersheets.
          </li>
          <li>
            Supersheets determines that such action is necessary to protect,
            maintain, or improve the Service; to prevent fraud or
            misrepresentation by affirmative acts and/or omissions; to protect
            Supersheets, our clients, or other third parties affiliated with
            Supersheets; or for any other good cause.
          </li>
        </List>
        <Para>
          Either You or Supersheets may terminate this Agreement without cause
          upon thirty (30) days' prior written notice, except insofar as it
          relates to any Premium Services which may only be terminated in
          accordance with the relevant terms.
        </Para>
        <Heading>
          <Bold>Termination Consequences</Bold>
        </Heading>
        <Para>
          Upon any termination or suspension of your account, Supersheets may
          immediately deactivate or delete your account and all related
          information and files in your account and/or restrict any further
          access to such files, information, or Service.
        </Para>
        <Para>
          Supersheets shall not be liable to you or any third party for any
          reason for terminating or suspending your use or access to Supersheets
          or the Service.
        </Para>
        <Para>
          The provisions of this Agreement relating to intellectual property
          ownership, Client representations and warranties, confidentiality, use
          policies and restrictions, storage of user information, publicity
          rights, payment terms, indemnification, force majeure, warranty
          disclaimers, limitations of liability, notices, assignment,
          modifications, interpretation, dispute resolution and choice of law
          shall survive termination or expiration of this Agreement for the
          maximum term allowable by Law.
        </Para>
        <Heading>
          <Bold>Representations and Warranties</Bold>
        </Heading>
        <Para>
          Each party represents and warrants that it has the legal power and
          authority to enter into this Agreement.
        </Para>
        <Para>
          Supersheets represents and warrants that it will provide the Service
          in a manner consistent with general industry standards reasonably
          applicable to the provision thereof and that the Service will perform
          substantially as documented under normal use and circumstances. The
          sole remedy for breach of this warranty shall be correction of any
          Defects by Supersheets within a reasonable time from notification by
          the Client of the Defect that constitutes such a breach, providing
          that such remedial work is commercially practicable, and the Client
          provides all the information that may be necessary to assist
          Supersheets in resolving the Defect, including sufficient information
          to enable Supersheets to recreate the Defect.
        </Para>
        <Para>
          You represent, warrant and undertake to Supersheets that: (i) the
          Client Data you provide or make available is accurate, current, and
          complete, and agree to promptly update any of the information if it
          changes; (ii) you have obtained all rights, licences and consents
          necessary to grant the licences to Supersheets pursuant to this
          Agreement; (iii) the Client Data will not infringe the rights of any
          third party, including any Intellectual Property Rights, rights in
          confidential information or rights in privacy; (iv) you have obtained
          all necessary permissions and consents from any persons associated
          with or identifiable from the Client Data (including the Users); (v)
          your provision of the Client Data is made in compliance with all
          applicable Law and the processing and use of such Client Data by or on
          behalf of Supersheets shall not cause Supersheets to breach any
          applicable Law or infringe the rights of any third party.
        </Para>
        <Heading>
          <Bold>Confidential Information</Bold>
        </Heading>
        <Para>
          Supersheets will use its reasonable commercial endeavours to keep all
          Client Data confidential, providing that Client Data may be disclosed
          to Supersheets's employees, representatives, consultants, contractors
          or agents (who in turn will be legally bound to keep the Client Data
          confidential). The obligation to keep the Client Data confidential
          will not apply to any information that: (i) is already known to the
          public; or (ii) is required to be disclosed by Law, by any
          governmental or other regulatory authority, or by a court or other
          authority of competent jurisdiction, providing that Supersheets will
          give the Client notice of the requirement to disclose of that
          disclosure as soon as practicable.
        </Para>
        <Heading>
          <Bold>Indemnification</Bold>
        </Heading>
        <Para>
          The Client shall indemnify and hold Supersheets, its licensors and
          each such party's parent organisations, subsidiaries, affiliates,
          officers, directors, employees, attorneys and agents harmless from and
          against any and all claims, costs, damages, losses, liabilities and
          expenses (including legal fees and costs) arising out of or in
          connection with: (i) a claim alleging that use or disclosure of the
          Client Data infringes the rights of, or has caused harm to, a third
          party (including the Users); (ii) a claim, which if true, would
          constitute a violation by the Client of their representations and
          warranties under this Agreement; or (iii) a claim arising from the
          breach by the Client or their Users of this Agreement.
        </Para>
        <Para>
          In connection with a claim for indemnification pursuant to this Clause
          15, Supersheets shall: (a) give written notice of the claim promptly
          to the Client; (b) give the Client sole control of the defence and
          settlement of the claim (provided that the Client may not settle or
          defend any claim unless they unconditionally release Supersheets of
          all liability and such settlement does not affect Supersheets's
          business or Service); (c) provide to the Client all reasonably
          relevant and available information and assistance; and (d) not
          compromise or settle such claim, without your consent.
        </Para>
        <Heading>
          <Bold>Disclaimer of Warranties</Bold>
        </Heading>
        <Para>
          Supersheets and its licensors do not guarantee that: (i) Supersheets
          or the Service will operate error free or without interruption; (ii)
          all program defects in relation to the Services will be corrected; or
          (iii) Supersheets or the Service will operate with any hardware,
          software, system or data not identified in the ordering process.
        </Para>
        <Para>
          All conditions, representations and warranties, whether express,
          implied, statutory or otherwise, including, without limitation, any
          implied warranty of satisfactory quality, fitness for a particular
          purpose, or non-infringement of third party rights, are hereby
          disclaimed to the maximum extent permitted by applicable Law by
          Supersheets and its licensors. Supersheets makes no representations or
          warranties that the Service is free of rightful claims of any third
          party for infringement or misappropriation of intellectual property or
          other proprietary rights.
        </Para>
        <Para>
          In addition, Supersheets makes no representation nor does it warrant,
          endorse, guarantee, or assume responsibility for any Third Party
          Services (or the content thereof).
        </Para>
        <Para>
          Although every effort is made to ensure that data transmissions of
          Client Data are secure, Supersheets makes no guarantees of security.
        </Para>
        <Heading>
          <Bold>Internet Delays</Bold>
        </Heading>
        <Para>
          Supersheets's Service may be subject to limitations, delays, and other
          problems inherent in the use of the Internet and electronic
          communications. Supersheets is not responsible for any delays,
          delivery failures, or other damage resulting from such problems.
        </Para>
        <Heading>
          <Bold>Force Majeure</Bold>
        </Heading>
        <Para>
          In this Agreement, "force majeure" shall mean any cause preventing
          Supersheets from performing any or all of Supersheets's obligations
          which arise from or are attributable to acts, events, omissions or
          accidents beyond Supersheets's reasonable control including without
          limitation strikes, lock-outs or other industrial disputes, acts of
          God, war, riot, civil commotion, malicious damage, compliance with any
          law or governmental order, rule, regulation or direction, accident,
          breakdown of plant or machinery, fire, flood, interruption or failure
          of utility service, including but not limited to electric power, gas
          or water or default of suppliers or sub-contractors. Supersheets shall
          not be in breach of this Agreement if it is subject to a force majeure
          event, provided that it uses reasonable endeavours to notify you in
          writing of the nature and extent of the force majeure event causing
          Supersheets's failure or delay in performance. If the force majeure
          event prevails for a continuous period of more than 2 months, the
          Client may terminate this Agreement by giving 14 days' written notice
          to Supersheets. On the expiry of this notice period, this Agreement
          will terminate. Such termination shall be without prejudice to
          Supersheets's rights in respect of any breach of this agreement
          occurring prior to such termination.
        </Para>
        <Heading>
          <Bold>Limitation of Liability</Bold>
        </Heading>
        <Para>
          Nothing in this Agreement will exclude or limit Supersheets's
          liability for: (i) death or personal injury caused by Supersheets's
          negligence; (ii) fraud or fraudulent misrepresentation; or (iii) any
          other liability which may not be excluded or limited under Applicable
          Law.
        </Para>
        <Para>
          Supersheets shall not be liable for any damages or losses as a result
          of a force majeure event, or from your or your Users' use of a Third
          Party Service.
        </Para>
        <Para>
          To the fullest extent permitted by Law, in no event shall Supersheets
          or its affiliates be liable to you or any third party for indirect,
          incidental, consequential, or punitive damages of any kind whether
          arising under contract, warranty, tort (including negligence or strict
          liability), or any other theory of liability, even if Supersheets has
          been informed in advance of such damages or such damages could have
          been reasonably foreseen by Supersheets.
        </Para>
        <Para>
          Without limiting the foregoing, Supersheets shall not be liable for
          any of the following losses (whether direct, indirect, incidental or
          consequential): (i) any loss of profits or other economic advantage;
          (ii) any loss of data; (iii) any loss of goodwill; and/or (iv) any
          loss of anticipated savings, arising in respect of any representation,
          statement, act or omission in connection with this Agreement, whether
          the claim arises under contract, tort, misrepresentation or breach of
          statutory duty.
        </Para>
        <Para>
          For Clients which are not Premium Service users, subject to paragraph
          1 of this clause, in no event shall Supersheets's aggregate liability
          exceed the sum of £10,000 in total. In recognition of the free nature
          of the Service, you acknowledge and agree that such allocation of
          liability between us is reasonable, appropriate and fair in the
          circumstances.
        </Para>
        <Para>
          For Clients which are Premium Service users, subject to paragraph 1 of
          this clause, in no event shall Supersheets's aggregate liability
          exceed the greater of: (a) sum of £10,000; and (b) the sum equal to
          the total sums paid by you to us in connection with the Premium
          Service(s) during the immediately preceding 12 months.
        </Para>
        <Para>
          The limitation of liability reflects the allocation of risk between
          the parties. The limitations specified in this section will survive
          and apply in any and all circumstances.
        </Para>
        <Heading>
          <Bold>Notice</Bold>
        </Heading>
        <Para>
          Supersheets may give notice by means of electronic mail to the
          Client's e-mail address on record in Supersheets's account
          information. Such notice shall be deemed to have been given upon the
          expiration 12 hours after sending the email.
        </Para>
        <Heading>
          <Bold>Modification to Terms</Bold>
        </Heading>
        <Para>
          Supersheets reserves the right to modify the terms and conditions of
          this Agreement or its policies relating to the Service at any time.
          Such modified terms and conditions will be issued or made available to
          the Client electronically via email or the Website and shall be deemed
          effective 12 hours after electronic delivery or notification.
          Continued use of the Service after any such changes shall constitute
          the Client's consent to such changes.
        </Para>
        <Heading>
          <Bold>Assignment; Change in Control</Bold>
        </Heading>
        <Para>
          This Agreement may not be assigned by the Client without the prior
          written approval of Supersheets but may be assigned without the
          Client's consent by Supersheets to: (i) a parent or subsidiary; (ii)
          an acquirer of assets; or (iii) a successor by merger.
        </Para>
        <Para>
          Any purported assignment in violation of this section shall be void.
        </Para>
        <Para>
          Any actual or proposed change in control of the Client that results or
          would result in a direct competitor of Supersheets directly or
          indirectly owning or controlling 50% or more of the Client shall
          entitle Supersheets to terminate this Agreement for cause immediately
          upon written notice.
        </Para>
        <Heading>
          <Bold>General</Bold>
        </Heading>
        <Para>
          This Agreement shall be governed by and construed in accordance with
          the laws of England and Wales and the parties agree that any dispute
          relating to its terms or subject matter shall be subject to the
          exclusive jurisdiction of the English courts.
        </Para>
        <Para>
          If any provision of this Agreement is held by a court of competent
          jurisdiction to be invalid or unenforceable, then such provision(s)
          shall be construed, as nearly as possible, to reflect the intentions
          of the invalid or unenforceable provision(s), with all other
          provisions remaining in full force and effect.
        </Para>
        <Para>
          No joint venture, partnership, employment, or agency relationship
          exists between the Client and Supersheets as a result of this
          Agreement or use of the Service.
        </Para>
        <Para>
          The failure of Supersheets to enforce any right or provision in this
          Agreement shall not constitute a waiver of such right or provision
          unless acknowledged and agreed to by Supersheets in signed writing
          authorised by a director of Supersheets.
        </Para>
        <Para>
          This Agreement together with Supersheets's privacy policy and any
          copyright notices on the Website comprises the entire agreement
          between the Client and Supersheets in relation to the Service and
          supersedes all prior or contemporaneous negotiations, discussions or
          agreements, whether written or oral, between the parties regarding the
          subject matter contained herein.
        </Para>
        <Heading>
          <Bold>Third parties</Bold>
        </Heading>
        <Para>
          For the purposes of the Contracts (Rights of Third Parties) Act 1999
          this Agreement is not intended to, and does not, give any person who
          is not a party to it any right to enforce any of its provisions.
        </Para>
        <Heading>
          <Bold>Acceptance of Agreement</Bold>
        </Heading>
        <Para>
          By ticking the acceptance field during the registration process, you
          confirm acceptance of this End User Licence Agreement, which shall
          constitute a binding contract between Supersheets and the Client,
          subject to the ability of Supersheets within 21 days to reject the
          Client.
        </Para>
        <Heading>
          <Bold>Company information</Bold>
        </Heading>
        <Para>
          Supersheets Limited is incorporated under the laws of England, with
          company number 13566223. Supersheets's registered address is:
        </Para>
        <Para>
          Flat 1 2b Smedley Street
          <br />
          London
          <br />
          SW4 6PG
          <br />
          United Kingdom
        </Para>
      </div>{" "}
    </Container>
  </Layout>
);

export default Terms;

const List = (props) => (
  <ul className="list-disc list-inside py-2 md:py-4">{props.children}</ul>
);

const Heading = (props) => (
  <h2 className="text-2xl font-serif pb-2 pt-6">{props.children}</h2>
);

const Para = (props) => <p className="py-2">{props.children}</p>;

const Bold = (props) => <span className="font-semibold">{props.children}</span>;
